import { useQuery } from 'react-query';
import { useIntentContext } from '../../hooks';

// API REQUESTS
import { fetchIntentQueryViewData } from 'api';

// COMPONENTS
import OutletSpinner from '../OutletSpinner';
import QueryViewTable from './QueryViewTable';

// CONSUMER INTENT QUERY VIEW TAB
function QueryView() {
  const { dateRange, selectedProducts, selectedVerticals } = useIntentContext();

  const { data, isLoading } = useQuery(
    ['consumerIntentQueryView', dateRange?.startDate, dateRange?.endDate],
    () =>
      fetchIntentQueryViewData(dateRange, selectedVerticals, selectedProducts),
    {
      enabled: !!dateRange?.startDate && !!dateRange.endDate,
      refetchOnWindowFocus: false,
    }
  );

  data?.query_view_data.sort(({ Demand: a }, { Demand: b }) => {
    if (a > b) return -1;
    if (a < b) return 1;
    return 0;
  });

  // JSX
  return isLoading ? (
    <OutletSpinner />
  ) : (
    <div className="pt-4">
      <QueryViewTable
        data={data?.query_view_data || null}
        products={selectedProducts}
        verticals={selectedVerticals}
      />
    </div>
  );
}

export default QueryView;
