import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const FinalPage: React.FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const handleRedirect = () => {
      navigate('/home');
    };

    const timer = setTimeout(() => {
      handleRedirect();
    }, 3000);

    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <div className="flex flex-col justify-center items-center h-screen text-center bg-light">
      <div className="flex flex-col justify-center items-center h-1/3">
        <h1 className="text-3xl font-bold">Congratulations! You have submit<br></br> successfully the new account</h1>
        <p className="text-thirdgray font-bold p-4">You will be redirect to the Home page.</p>
      </div>
      <div className="relative my-8 h-1/3 flex justify-center items-center">
        <img
          src={`${process.env.PUBLIC_URL}/static/img/final_circle.svg`}
          alt="circle"
          className="w-[246px] h-[246px]"
        />
        <img
          src={`${process.env.PUBLIC_URL}/static/img/final_checkmark.svg`}
          alt="checkmark"
          className="w-[92.25px] h-[61.5px] absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
        />
      </div>
      <div className="h-1/3 flex justify-center items-center">
        <button 
          onClick={() => navigate('/home')} 
          className="mt-4 px-6 py-2 bg-success text-light text-sm font-bold rounded-[20px]">
          PROCEED TO ONESEARCH
        </button>
      </div>
    </div>
  );
};

export default FinalPage;
