import { useEffect, useMemo, useState } from 'react';

// COMPONENTS
import MultiDropdown from '../MultiDropdown';
import DataTable, { TableColumns } from '../DataTable';

// TYPES
import { QueryViewData } from '../../types';

const columns: TableColumns<QueryViewData>[] = [
  { header: 'Query', bind: 'Queries' },
  { header: 'Mindset', bind: 'Mindset' },
  { header: 'Vertical', bind: 'Vertical' },
  { header: 'Brand', bind: 'Brand' },
  { header: 'Product', bind: 'Product' },
  { header: 'Feature Group', bind: 'Feature_Group' },
  { header: 'Feature', bind: 'Feature' },
  { header: 'Retailer', bind: 'Retailer' },
  { header: 'Demand', bind: 'Demand' },
];

type QueryViewFilters = {
  queries: string[];
  brands: string[];
  mindsets: string[];
  featureGroups: string[];
};

const DEFAULT_STATE: QueryViewFilters = {
  queries: [],
  brands: [],
  mindsets: [],
  featureGroups: [],
};

interface QueryViewTableProps {
  data: QueryViewData[] | null;
  products: string[];
  verticals: string[];
}

function QueryViewTable({ data, products, verticals }: QueryViewTableProps) {
  const [filters, setFilters] = useState<QueryViewFilters>({
    ...DEFAULT_STATE,
  });

  const options = useMemo((): QueryViewFilters => {
    const queries = new Set<string>();
    const brands = new Set<string>();
    const products = new Set<string>();
    const mindsets = new Set<string>();
    const featureGroups = new Set<string>();

    for (const row of data || []) {
      queries.add(row.Queries || 'null');
      brands.add(row.Brand || 'null');
      products.add(row.Product || 'null');
      mindsets.add(row.Mindset || 'null');
      featureGroups.add(row.Feature_Group || 'null');
    }
    return {
      queries: Array.from(queries),
      brands: Array.from(brands),
      mindsets: Array.from(mindsets),
      featureGroups: Array.from(featureGroups),
    };
  }, [data]);

  useEffect(() => {
    setFilters({ ...options });
  }, [options, setFilters]);

  const onSetFilter = (
    selections: string[],
    filter: keyof QueryViewFilters
  ) => {
    setFilters((prev) => ({ ...prev, [filter]: [...selections] }));
  };

  // FILTER DATA BASED ON FILTER SELECTIONS
  const filteredData = useMemo(() => {
    if (!data) return null;
    const output: QueryViewData[] = [];

    const filter = {
      Queries: new Set(filters.queries),
      Vertical: new Set(verticals),
      Brand: new Set(filters.brands),
      Mindset: new Set(filters.mindsets),
      Product: new Set(products),
      Feature_Group: new Set(filters.featureGroups),
    };
    const filterKeys = Object.keys(filter) as (keyof typeof filter)[];
    for (const row of data) {
      let isValid = true;
      for (const key of filterKeys) {
        if (!isValid || !filter[key]?.has(row[key] || 'null')) {
          isValid = false;
          break;
        }
      }
      if (isValid) {
        output.push(row);
      }
    }

    return output;
  }, [data, filters, products, verticals]);

  // JSX
  return (
    <DataTable
      above={
        <div className="flex justify-between">
          <MultiDropdown
            placeholder={'Queries'}
            options={options.queries}
            preselectedOptions={filters.queries}
            onSelectionChange={(selections) => {
              onSetFilter(selections, 'queries');
            }}
            dropdownLength="max-h-60"
            alignMenu="start"
            className="max-w-48"
          />
          <MultiDropdown
            placeholder={'Brand'}
            options={options.brands}
            preselectedOptions={filters.brands}
            onSelectionChange={(selections) => {
              onSetFilter(selections, 'brands');
            }}
            dropdownLength="max-h-60"
            alignMenu="center"
            className="max-w-48"
          />
          <MultiDropdown
            placeholder={'Feature Group'}
            options={options.featureGroups}
            preselectedOptions={filters.featureGroups}
            onSelectionChange={(selections) => {
              onSetFilter(selections, 'featureGroups');
            }}
            dropdownLength="max-h-60"
            alignMenu="center"
            className="max-w-48"
          />
          <MultiDropdown
            placeholder={'Mindset'}
            options={options.mindsets}
            preselectedOptions={filters.mindsets}
            onSelectionChange={(selections) => {
              onSetFilter(selections, 'mindsets');
            }}
            dropdownLength="max-h-60"
            className="max-w-48"
          />
        </div>
      }
      data={filteredData}
      columns={columns}
      itemsPerPage={50}
    />
  );
}

export default QueryViewTable;
