import { useEffect, useState } from 'react';

interface TablePaginatorProps {
  itemsPerPage: number;
  totalItems: number;
  onChange: (startIndex: number, endIndex: number) => void;
}

function TablePaginator({
  itemsPerPage,
  totalItems,
  onChange,
}: TablePaginatorProps) {
  const [currentPage, setCurrentPage] = useState(0);
  const maxPages = Math.floor(totalItems / itemsPerPage);

  // HANDLE PAGE FORWARD
  const onPageForward = () => {
    setCurrentPage((curr) => {
      const nextPage = curr + 1;
      return Math.min(nextPage, maxPages);
    });
  };

  // HANDLE PAGE BACK
  const onPageBack = () => {
    setCurrentPage((curr) => {
      const prevPage = curr - 1;
      return Math.max(prevPage, 0);
    });
  };

  useEffect(() => {
    // inclusive
    const startIndex = Math.min(currentPage * itemsPerPage, totalItems);
    // exclusive
    const endIndex = Math.min(startIndex + itemsPerPage, totalItems);
    onChange(startIndex, endIndex);
  }, [currentPage, totalItems, itemsPerPage, onChange]);

  return (
    <div className="flex flex-col lg:flex-row justify-end items-center px-5 py-2">
      <span className="text-xs text-gray700 font-normal mr-3 mb-2 lg:mb-0">
        Showing
        <span className="text-secondarygray font-bold tabular-nums">
          {' '}
          {Math.min(currentPage * itemsPerPage + 1, totalItems)}
        </span>{' '}
        to
        <span className="text-secondarygray font-bold tabular-nums">
          {' '}
          {Math.min((currentPage + 1) * itemsPerPage, totalItems)}
        </span>{' '}
        of
        <span className="text-secondarygray font-bold tabular-nums">
          {' '}
          {totalItems}
        </span>{' '}
        Entries
      </span>
      <div className="flex items-center relative">
        <button
          onClick={onPageBack}
          disabled={currentPage === 0}
          className="text-sm text-light bg-primarygray px-3 py-[6px] rounded-l-lg disabled:cursor-not-allowed"
        >
          PREV
        </button>
        <div
          style={{ width: '1px', backgroundColor: 'white', margin: '0.1px' }}
        ></div>
        <button
          onClick={onPageForward}
          disabled={currentPage === maxPages}
          className="text-sm text-light bg-primarygray px-3 py-[6px] rounded-r-lg disabled:cursor-not-allowed"
        >
          NEXT
        </button>
      </div>
    </div>
  );
}

export default TablePaginator;
