import { useState, useEffect } from 'react';

// COMPONENTS
import APIsCfg from './APIsCfg';
import Insights from './Insights';
import LoadingSpinner from './Spinner';
import ConsumerInt from './ConsumerInt';
import AutoOptimize from './AutoOptimize';
import Competitive_int from './Competitive_int';
import PleaseWaitPage from '../Onboarding_comp/Configuration_comp/PleaseWaitPage'; // Import your loading component

// UTILS
import { sortFuncStrings } from 'utils';

// TYPES
import { User } from '../../protectedRoute';

interface CustomerInfo {
  customer_id: string;
  agency: string;
  client: string;
  line_of_business: string;
  market: string;
  region: string;
  platform: string[];
  status: string;
  new_business: boolean; // New Business flag
}

interface EditPageProps {
  customer: CustomerInfo;
  loggedInUser: User | null;
}

function EditPage({ customer, loggedInUser }: EditPageProps) {
  const [enabledPlatforms, setEnabledPlatforms] = useState(customer.platform); // Current platforms
  const [initialPlatforms] = useState(customer.platform); // Keep track of initial platforms
  const [showUpdateButton, setShowUpdateButton] = useState(false);
  const [currentStatus, setCurrentStatus] = useState(customer.status); // Track the current status
  const [initialStatus] = useState(customer.status); // Track initial status to compare
  const [isStatusChanged, setIsStatusChanged] = useState(false); // Track if status changed
  const [savingStatus, setSavingStatus] = useState(false); // Handle status save state
  const [loading, setLoading] = useState(false); // New state for loading screen
  const [onboardingStatus, setOnboardingStatus] = useState<
    'pending' | 'success' | 'failure' | undefined
  >(undefined); // Track onboarding process status
  const [new_business, setnew_business] = useState(customer.new_business); // Track the New Business flag state
  const [activeTab, setActiveTab] = useState(
    customer.new_business ? 'Competitive_int' : 'APIsCfg'
  ); // Default tab based on new_business flag
  const [isNewBusinessChanged, setIsNewBusinessChanged] = useState(false); // Track if New Business flag changed
  const [savingNewBusiness, setSavingNewBusiness] = useState(false); // Track if saving New Business flag

  // Available status options
  const statusOptions = ['InProgress', 'Active', 'Onboarding', 'Inactive'];

  // Handle platform change and show Update Onboarding button if there are changes
  useEffect(() => {
    if (
      (customer.status === 'Active' ||
        customer.status === 'Onboarding' ||
        customer.status === 'Inactive') &&
      JSON.stringify(enabledPlatforms) !== JSON.stringify(initialPlatforms)
    ) {
      setShowUpdateButton(true);
    } else {
      setShowUpdateButton(false);
    }
  }, [enabledPlatforms, customer.status, initialPlatforms]);

  const handleSaveNewBusiness = () => {
    setSavingNewBusiness(true);
    fetch('update-new-business/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ customer_id: customer.customer_id, new_business }),
    })
      .then((response) => {
        if (response.ok) {
          setIsNewBusinessChanged(false); // Disable save button after save
        }
      })
      .catch((error) => {
        console.error('Error saving New Business flag:', error);
      })
      .finally(() => {
        setSavingNewBusiness(false); // Reset saving state
      });
  };

  // Function to handle onboarding process
  const handleOnboarding = () => {
    setLoading(true); // Show loading screen
    setOnboardingStatus('pending'); // Set onboarding to pending

    fetch('start-onboarding/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        customer_id: customer.customer_id,
        enabled_platforms: enabledPlatforms,
      }),
    })
      .then((response) => {
        if (response.ok) {
          setOnboardingStatus('success');
          // After a delay (e.g., 2 seconds), hide the loading screen
          setTimeout(() => {
            setLoading(false);
          }, 2000);
        } else {
          throw new Error('Failed to start onboarding.');
        }
      })
      .catch((error) => {
        console.error('Error:', error);
        setOnboardingStatus('failure'); // Set status to failure if an error occurs
        setLoading(false); // Hide loading screen
      });
  };

  // Handle the status change event
  const handleStatusChange = (newStatus: string) => {
    setCurrentStatus(newStatus);
    setIsStatusChanged(newStatus !== initialStatus); // Enable save button if status changed
  };

  // Handle saving the updated status
  const handleSaveStatus = () => {
    setSavingStatus(true);
    fetch('update-status/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        customer_id: customer.customer_id,
        status: currentStatus,
        enabled_platforms: enabledPlatforms,
      }),
    })
      .then((response) => {
        setSavingStatus(false);
        setIsStatusChanged(false); // Disable save button after save
      })
      .catch((error) => {
        setSavingStatus(false);
        console.error('Error:', error);
      });
  };

  const fields = [
    { label: 'Agency', value: customer.agency },
    { label: 'Client', value: customer.client },
    { label: 'Line of Business', value: customer.line_of_business },
    { label: 'Market', value: customer.market },
    { label: 'Region', value: customer.region },
  ];

  const moduleMap: { [key: string]: string } = {
    '1': 'Insights',
    '2': 'Competitive Intelligence',
    '3': 'Consumer Intent',
    '4': 'Auto Optimize',
  };

  const isPlatformEnabled = (platformId: string) =>
    enabledPlatforms.includes(platformId);

  const enablePlatform = (platformId: string) => {
    setEnabledPlatforms([...enabledPlatforms, platformId]);
  };

  const disablePlatform = (platformId: string) => {
    setEnabledPlatforms(enabledPlatforms.filter((id) => id !== platformId));
  };

  const gridColsClass = 'grid-cols-4'; // Always keep 4 columns

  // Separate enabled and disabled platforms
  const enabledPlatformsList = Object.keys(moduleMap).filter(isPlatformEnabled);
  const disabledPlatformsList = Object.keys(moduleMap).filter(
    (platformId) => !isPlatformEnabled(platformId)
  );

  // If loading is true, show the loading screen instead of the normal content
  if (loading) {
    return (
      <PleaseWaitPage
        status={onboardingStatus}
        message="We are creating your account!"
      />
    );
  }

  return (
    <>
      <div className="bg-light rounded-2xl shadow-sm mt-8">
        <div className="border-b border-success mb-2">
          <div className="flex justify-between items-center">
            <div>
              {/* missing text subtitle */}
              <h2 className="text-primarygray text-2xl font-bold pl-4 py-2">
                Edit Account
              </h2>
            </div>
            {/* Conditionally render the onboarding button */}
            {!new_business && currentStatus === 'InProgress' ? (
              <button
                onClick={handleOnboarding}
                className="text-sm font-bold py-2 px-4 rounded-full items-center bg-blue800 text-light mr-4"
              >
                Start Onboarding
              </button>
            ) : (
              showUpdateButton &&
              !new_business && (
                <button
                  onClick={handleOnboarding}
                  className="text-sm font-bold py-2 px-4 rounded-full items-center bg-blue800 text-light mr-4"
                >
                  Update Onboarding
                </button>
              )
            )}
          </div>
        </div>

        {/* Toggle for New Business */}
        {new_business ? (
          <div className="flex items-center space-x-4 px-4 py-4">
            <label className="font-bold text-sm">New Business:</label>
            <input
              type="checkbox"
              checked={new_business}
              onChange={() => {
                setnew_business(!new_business);
                setIsNewBusinessChanged(true); // Mark as changed
                setActiveTab(new_business ? 'APIsCfg' : 'Competitive_int');
              }}
              className="h-5 w-5 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
            />
            <button
              onClick={handleSaveNewBusiness}
              className={`text-sm font-bold py-2 px-4 rounded-full items-center ${
                isNewBusinessChanged ? 'bg-green-600' : 'bg-gray-300'
              } text-light`}
              disabled={!isNewBusinessChanged || savingNewBusiness}
            >
              {savingNewBusiness ? <LoadingSpinner /> : 'Save New Business'}
            </button>
          </div>
        ) : null}

        {/* Status Selector */}
        <div className="flex items-center space-x-4 px-4 py-4">
          <label className="font-bold text-sm">Status:</label>
          <select
            value={currentStatus}
            onChange={(e) => handleStatusChange(e.target.value)}
            className="border border-gray-300 rounded p-2 text-sm"
            disabled={new_business}
          >
            {statusOptions.sort(sortFuncStrings).map((status) => (
              <option key={status} value={status}>
                {status}
              </option>
            ))}
          </select>
          <button
            onClick={handleSaveStatus}
            className={`text-sm font-bold py-2 px-4 rounded-full items-center ${
              isStatusChanged && !new_business ? 'bg-green-600' : 'bg-gray-300'
            } text-light`}
            disabled={!isStatusChanged || savingStatus || new_business}
          >
            {savingStatus ? <LoadingSpinner /> : 'Save Status'}
          </button>
        </div>

        {/* Fields Section */}
        <div className="flex flex-col space-y-4 px-4 py-4">
          <div className="grid grid-cols-5 gap-4">
            {fields.map((field, index) => (
              <div key={index} className="flex flex-col space-y-2">
                <label className="font-bold text-sm">{field.label}</label>
                <input
                  type="text"
                  className="border border-gray200 rounded p-2 text-sm"
                  value={field.value}
                  readOnly
                />
              </div>
            ))}
          </div>

          {/* Platform Indicators */}
          {!new_business && (
            <div className="flex flex-col space-y-2">
              <label className="font-bold text-sm">Modules</label>
              <div className={`grid ${gridColsClass} gap-6 text-sm font-bold`}>
                {/* Show enabled platforms first */}
                {enabledPlatformsList.map((platformId) => (
                  <div
                    key={platformId}
                    className="flex flex-row items-center justify-between space-x-2 bg-green-100 border-success rounded border px-4 py-2"
                    style={{
                      boxShadow:
                        '0px 2px 17px 0px rgba(0, 0, 0, 0.08), 0px 0px 1px 0px #FEFCFD',
                    }}
                  >
                    <span>{moduleMap[platformId]}</span>
                    <div className="flex space-x-2">
                      <button>
                        <img
                          src={`${process.env.PUBLIC_URL}/static/img/play.svg`}
                          alt="play"
                          className="h-6 w-6"
                        />
                      </button>
                      <button onClick={() => disablePlatform(platformId)}>
                        <img
                          src={`${process.env.PUBLIC_URL}/static/img/pause.svg`}
                          alt="pause"
                          className="h-6 w-6"
                        />
                      </button>
                    </div>
                  </div>
                ))}

                {/* Show disabled platforms at the end */}
                {disabledPlatformsList.map((platformId) => (
                  <div
                    key={platformId}
                    className="flex flex-row items-center justify-between space-x-2 bg-gray-100 border-gray300 rounded border px-4 py-2"
                    style={{
                      boxShadow:
                        '0px 2px 17px 0px rgba(0, 0, 0, 0.08), 0px 0px 1px 0px #FEFCFD',
                    }}
                  >
                    <span>{moduleMap[platformId]}</span>
                    <div className="flex space-x-2">
                      <button onClick={() => enablePlatform(platformId)}>
                        <img
                          src={`${process.env.PUBLIC_URL}/static/img/play.svg`}
                          alt="play"
                          className="h-6 w-6"
                        />
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}

          {/* Tabs */}
          {!new_business && (
            <div className="flex space-x-4 border-b-2 mb-4">
              {/* Always show APIsCfg tab */}
              <button
                onClick={() => setActiveTab('APIsCfg')}
                className={`text-sm font-bold px-4 py-2 ${
                  activeTab === 'APIsCfg'
                    ? 'border-b-2 border-blue800 text-blue800'
                    : ''
                }`}
              >
                APIs Configuration
              </button>

              {/* Show Competitive Intelligence tab */}
              <button
                onClick={() => setActiveTab('Competitive_int')}
                className={`text-sm font-bold px-4 py-2 ${
                  activeTab === 'Competitive_int'
                    ? 'border-b-2 border-blue800 text-blue800'
                    : ''
                }`}
              >
                Competitive Intelligence
              </button>

              {/* Show other tabs if New Business is False */}
              {enabledPlatformsList.includes('1') && (
                <button
                  onClick={() => setActiveTab('Insights')}
                  className={`text-sm font-bold px-4 py-2 ${
                    activeTab === 'Insights'
                      ? 'border-b-2 border-blue800 text-blue800'
                      : ''
                  }`}
                >
                  Insights
                </button>
              )}
              {enabledPlatformsList.includes('3') && (
                <button
                  onClick={() => setActiveTab('ConsumerInt')}
                  className={`text-sm font-bold px-4 py-2 ${
                    activeTab === 'ConsumerInt'
                      ? 'border-b-2 border-blue800 text-blue800'
                      : ''
                  }`}
                >
                  Consumer Intent
                </button>
              )}
              {enabledPlatformsList.includes('4') && (
                <button
                  onClick={() => setActiveTab('AutoOptimize')}
                  className={`text-sm font-bold px-4 py-2 ${
                    activeTab === 'AutoOptimize'
                      ? 'border-b-2 border-blue800 text-blue800'
                      : ''
                  }`}
                >
                  Auto Optimize
                </button>
              )}
            </div>
          )}

          {/* Conditionally Render the Module Based on Active Tab */}
          {activeTab === 'APIsCfg' && (
            <APIsCfg customerId={customer.customer_id} />
          )}
          {activeTab === 'ConsumerInt' && (
            <ConsumerInt
              customerId={customer.customer_id}
              enabled={isPlatformEnabled('3')}
            />
          )}
          {activeTab === 'Competitive_int' && (
            <Competitive_int
              customerId={customer.customer_id}
              enabled={isPlatformEnabled('2')}
              loggedInUser={loggedInUser}
            />
          )}
          {activeTab === 'AutoOptimize' && (
            <AutoOptimize
              customerId={customer.customer_id}
              enabled={isPlatformEnabled('4')}
            />
          )}
          {activeTab === 'Insights' && (
            <Insights
              customerId={customer.customer_id}
              enabled={isPlatformEnabled('1')}
            />
          )}
        </div>
      </div>
    </>
  );
}

export default EditPage;
