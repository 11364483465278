import axios from 'axios';
import moment from 'moment';
import { subDays } from 'date-fns';

// TYPES
import {
  StudyItem,
  StudyData,
  CompetitorGroup,
  CompIntelReportType,
  OverviewDataSection,
  CompIntSearchOptions,
  GETCompIntelStudyList,
  CompIntelReportParams,
  GETCmpIntSearrchGroups,
  CompetitiveIntelligenceData,
} from '../../types';
import { DateValueType } from 'react-tailwindcss-datepicker';
import { TimeFrameDateRange } from '../../components/TimeFramePicker';

// UTILS
import { saveDownloadFile } from 'utils/download';

// FETCH SEARCH TERM OVERLAP DATA
export const fetchNewSearchTermOverlaps = async (
  startDate: string,
  brands: string,
  searchTerms: string[] = []
) => {
  const body = new FormData();
  body.append('date_from', startDate);
  body.append('date_to', startDate);
  body.append('brands', brands);
  body.append('search_terms', searchTerms.join(','));

  const { data } = await axios.post('/get_search_term_overlaps/', body);
  return data;
};

// FETCH OVERVIEW TAB DATA
export const fetchCompOverviewData = async (
  dateRange: TimeFrameDateRange | null = null,
  searchTerms: string[] = []
) => {
  if (!dateRange || !dateRange.endDate || !dateRange.startDate) return;
  const startDate = moment(dateRange.startDate).format('YYYY-MM-DD');
  const endDate = moment(dateRange.endDate).format('YYYY-MM-DD');

  const body = new FormData();
  body.append('date_from', startDate);
  body.append('date_to', endDate);
  body.append('search_terms', searchTerms.join(','));

  const { data } = await axios.post<OverviewDataSection>(
    '/get_comp_data/overview',
    body
  );

  return data;
};

// FETCH COMP INTEL BRAND FOCUS DATA
export const fetchCompBFData = async (
  timeFrame: TimeFrameDateRange | null,
  brands: string,
  searchTerms: string[] = []
  // searchTermsDate: string,
) => {
  if (!timeFrame || !timeFrame.startDate || !timeFrame.endDate) {
    return;
  }
  const startDate = moment(timeFrame.startDate).format('YYYY-MM-DD');
  const endDate = moment(timeFrame.endDate).format('YYYY-MM-DD');

  const body = new FormData();
  body.append('date_from', startDate);
  body.append('date_to', endDate);
  body.append('comp_brands', brands);
  body.append('search_terms', searchTerms.join(','));
  body.append(
    'search_terms_date:',
    subDays(new Date(), 1).toISOString().slice(0, 10)
  );

  const { data } = await axios.post('/get_comp_data/brand_focus', body);

  return data;
};

// FETCH COMP INTEL CONTENT ANALYSIS DATA
export const fetchCompCAData = async (
  dateRange: TimeFrameDateRange | null,
  searchTerms: string[] = []
) => {
  if (!dateRange || !dateRange.startDate || !dateRange.endDate) {
    return;
  }
  const startDate = moment(dateRange.startDate).format('YYYY-MM-DD');
  const endDate = moment(dateRange.endDate).format('YYYY-MM-DD');

  const body = new FormData();
  body.append('date_from', startDate);
  body.append('date_to', endDate);
  body.append('search_terms', searchTerms.join(','));

  const { data } = await axios.post('/get_comp_data/content_analysis', body);
  return data;
};

// FETCH STUDIES DATA
export const fetchStudiesData = async (): Promise<{
  studyList: StudyItem[];
  domain: string;
}> => {
  const response = await fetch('/study-list/');
  if (!response.ok) {
    throw new Error('Network response was not ok');
  }

  const compGroupData: StudyData = await response.json();
  if (compGroupData.study_list.length === 0 || !compGroupData.domain) {
    return { studyList: [], domain: '' };
  }

  const studyList = compGroupData.study_list;
  const domain = compGroupData.domain;

  return { studyList, domain };
};

// FETCH STUDIES LIST
export const fetchStudyList = async () => {
  const { studyList } = await fetchStudiesData();

  const { study_name } = studyList[0];

  const { data } = await axios.get<GETCompIntelStudyList>(
    '/competitive-intelligence-list/'
  );

  for (const { configurations } of data) {
    for (const config of configurations) {
      if (config.study_name === study_name) {
        return configurations;
      }
    }
  }
};

const validateDateRange = (
  dateRange: DateValueType,
  reportType: CompIntelReportType
) => {
  const startDate = dateRange?.startDate ? new Date(dateRange.startDate) : null;
  const endDate = dateRange?.endDate ? new Date(dateRange.endDate) : null;

  if (!dateRange) {
    throw new Error('Start and end date required.');
  }

  if (!startDate) {
    throw new Error('Start date required.');
  }

  if (!endDate) {
    throw new Error('End date is required');
  }

  if (reportType === 'content') {
    const max7Days = new Date(startDate);
    max7Days.setDate(max7Days.getDate() + 7);

    if (endDate > max7Days) {
      throw new Error('Maximum of 7 days for content report.');
    }
  }
  return true;
};

export const downloadCompIntelReport = async (
  params: CompIntelReportParams
) => {
  const { studyName, reportType, dateRange, fileType = 'xlsx' } = params;

  validateDateRange(dateRange, reportType);

  const startDate = moment(dateRange?.startDate).format('YYYY-MM-DD');
  const endDate = moment(dateRange?.endDate).format('YYYY-MM-DD');

  const url = `/get_comp_report/${reportType}/${fileType}/${startDate}/${endDate}`;

  const res = await axios.post<Blob>(url, {}, { responseType: 'blob' });

  const fileName = `${studyName}_${reportType}_${startDate}_thru_${endDate}`;
  saveDownloadFile(res, fileName);
};

// FETCH COMPETITOR GROUPS DATA
export const fetchCompetitorGroups = async (): Promise<{
  competitorGroups: CompetitorGroup[];
  allDomains: string[];
  compLocations: string[];
}> => {
  const response = await fetch('/competitor-groups-list/');
  if (!response.ok) {
    throw new Error('Network response was not ok');
  }

  const compGroupData: CompetitiveIntelligenceData = await response.json();
  let competitorGroups: CompetitorGroup[] = [];
  let allDomains: string[] = [];
  let compLocations: string[] = [];

  if (compGroupData.competitor_groups.length === 0) {
    competitorGroups = [];
    allDomains = [];
  } else {
    competitorGroups = compGroupData?.competitor_groups;
    allDomains = competitorGroups
      .map((group) => group.domains.split(',').map((domain) => domain.trim()))
      .flat();
  }
  if (compGroupData.geo_targeting.length === 0) {
    compLocations = [];
  } else {
    compLocations = compGroupData?.geo_targeting;
  }

  return { competitorGroups, allDomains, compLocations };
};

// FETCH COMP INTEL SEARCH TERM GROUPS
export const fetchCmpIntSearchGroups =
  async (): Promise<CompIntSearchOptions> => {
    const {
      data: { search_groups = [] },
    } = await axios.get<GETCmpIntSearrchGroups>('/search-groups-list/');

    const searchTermSet = new Set<string>();
    const groups: string[] = [];

    // {[group]: {[search_term]: search_term}}
    const groupData: { [key: string]: { [key: string]: string } } = {};

    for (const group of search_groups) {
      groups.push(group.group_name);

      if (!groupData[group.group_name]) {
        groupData[group.group_name] = {};
      }
      for (const term of group.search_terms.split(',')) {
        searchTermSet.add(term);
        groupData[group.group_name][term] = term;
      }
    }

    return {
      groups,
      terms: Array.from(searchTermSet),
      data: groupData,
    };
  };
