import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

interface PleaseWaitPageProps {
  message?: React.ReactNode;
  status?: 'pending' | 'success' | 'failure';
}

const PleaseWaitPage: React.FC<PleaseWaitPageProps> = ({ status, message }) => {
  const navigate = useNavigate();
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      if (status !== 'success')
        setProgress((prevProgress) => {
          const currentProgress = prevProgress + 1;
          // Reset progress after 100
          return Math.min(currentProgress >= 100 ? 0 : currentProgress, 100);
        });
    }, 30);

    if (status === 'success') {
      navigate('/final');
    }

    return () => {
      clearInterval(interval);
    };
  }, [navigate, status]);

  return (
    <div className="fixed w-screen z-50 top-0 left-0 flex justify-center items-center h-screen bg-light">
      <div className="w-9/12 text-center">
        <h1 className="text-[32px] font-bold my-4">Please wait...</h1>
        <h2 className="text-thirdgray font-bold mb-11">{message}</h2>
        <div className="relative pt-1">
          <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-fourthgray">
            <div
              style={{ width: `${progress}%` }}
              className="shadow-none flex flex-col text-center whitespace-nowrap text-light justify-center bg-success"
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PleaseWaitPage;
