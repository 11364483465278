import TablePaginator from './Table-Paginator';
import React, {
  useMemo,
  useState,
  useEffect,
  useCallback,
  ReactNode,
} from 'react';

// TYPES
import { SortDirection, TableColumns, TableData, TableHeaders } from './types';

interface DataTableProps<T extends TableData> {
  above?: React.ReactNode;
  pagination?: boolean;
  data: T[] | null;
  columns: TableColumns<T>[];
  itemsPerPage?: number;
}

function DataTable<T extends TableData>({
  above,
  data,
  columns,
  itemsPerPage = 10,
  pagination = true,
}: DataTableProps<T>) {
  const [tableData, setTableData] = useState<T[] | null>(null);
  const [dataIndexes, setDataIndexes] = useState<{
    start: number;
    end: number;
  }>({ start: 0, end: itemsPerPage });

  useEffect(() => {
    setTableData(data?.slice(0) || null);
    if (!pagination) {
      setDataIndexes({
        start: 0,
        end: data?.length || itemsPerPage,
      });
    }
  }, [data, pagination, itemsPerPage]);

  const onPageChange = useCallback((start: number, end: number) => {
    setDataIndexes({ start, end });
  }, []);

  const paginatedData = useMemo(
    () => tableData?.slice(dataIndexes.start, dataIndexes.end),
    [tableData, dataIndexes]
  );

  const tableHeaders: TableHeaders<T>[] = useMemo(
    () =>
      columns.map(({ header, bind, sortable = true }) => ({
        bind,
        header,
        sortable,
        currentSort: null,
      })),
    [columns]
  );

  const renderedRows = useMemo(
    () =>
      paginatedData?.map((row, index) => (
        <tr
          key={index}
          className={index % 2 === 0 ? 'bg-gray50' : 'bg-gray100'}
        >
          {columns.map(({ bind }) => {
            let value = row[bind] as ReactNode;

            if (value === null) {
              value = 'null';
            }

            if (typeof value === 'number') {
              value = value.toLocaleString();
            }
            return (
              <td key={bind as string} className="p-2">
                {value}
              </td>
            );
          })}
        </tr>
      )),
    [paginatedData, columns]
  );

  const onSortColumn = (key: keyof T, currentSort: SortDirection | null) => {
    const newSortDirection =
      !currentSort || currentSort === 'desc' ? 'asc' : 'desc';

    const headerIndex = tableHeaders.findIndex(({ bind }) => bind === key);

    if (headerIndex === undefined || !tableHeaders[headerIndex]) {
      throw new Error(`Unable to find column of bind [${key.toString()}]`);
    }

    tableHeaders[headerIndex].currentSort = newSortDirection;

    setTableData(
      (prev) =>
        prev?.slice(0).sort((a, b) => {
          if (a[key] === null || a[key] === undefined) {
            return newSortDirection === 'asc' ? 1 : -1;
          }
          if (b[key] === null || b[key] === undefined) {
            return newSortDirection === 'asc' ? -1 : 1;
          }

          if (a[key] < b[key]) {
            return newSortDirection === 'asc' ? -1 : 1;
          }
          if (a[key] > b[key]) {
            return newSortDirection === 'asc' ? 1 : -1;
          }
          return 0;
        }) || null
    );
  };

  // JSX
  return (
    <div className="bg-light rounded-lg p-6 w-full flex flex-col gap-4">
      {/* ---- ABOVE TABLE ----*/}
      {above ? <div>{above}</div> : null}

      {/* ---- TABLE CONTENT ---- */}
      <div className="overflow-auto max-h-[500px] relative">
        <table className="min-w-full bg-light table-auto">
          <thead className="text-primarygray text-xs font-normal sticky top-0 bg-light">
            <tr>
              {tableHeaders?.map(
                ({ header, bind, sortable, currentSort }, index) => (
                  <th key={index} className="p-2 text-left">
                    <div className="flex items-center uppercase">
                      {header}
                      {sortable && (
                        <button onClick={() => onSortColumn(bind, currentSort)}>
                          <img
                            src={`${process.env.PUBLIC_URL}/static/img/switch-vertical.svg`}
                            alt="sort-icon"
                            className="ml-2"
                          />
                        </button>
                      )}
                    </div>
                  </th>
                )
              )}
              {/* <th className="py-2 px-2 text-left">ACTIONS</th> */}
            </tr>
          </thead>
          <tbody className="text-primarygray text-xs font-normal rounded-md">
            {renderedRows}
          </tbody>
        </table>
      </div>

      {/* ---- PAGINATOR ---- */}
      {pagination ? (
        <TablePaginator
          totalItems={data?.length || 0}
          itemsPerPage={itemsPerPage}
          onChange={onPageChange}
        />
      ) : null}
    </div>
  );
}

export default DataTable;
